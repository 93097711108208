import React from 'react'
import PropTypes from 'prop-types'

const SectionDarkContext = React.createContext(false)

const Section = ({ dark, children, className }) => (
  <SectionDarkContext.Provider value={dark}>
    <section className={className}>{children}</section>
  </SectionDarkContext.Provider>
)

Section.propTypes = {
  dark: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Section.defaultProps = {
  dark: false,
  className: null,
}

export { Section, SectionDarkContext }
