import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'

import * as styles from './Line.module.scss'

const Line = ({ size, className, reveal }) => (
  <div
    className={cx([
      styles.line,
      size === 'big' ? styles.lineBig : styles.lineSmall,
      className,
      reveal && styles.lineReveal,
    ])}
  />
)

Line.propTypes = {
  size: PropTypes.oneOf(['big', 'small']),
}

export { Line }
