import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import { Spring } from 'react-spring/renderprops'
import { VisibilitySensorOnce } from '../VisibilitySensorOnce'
import cx from 'clsx'
import { SectionDarkContext } from '../Section/Section'

import * as styles from './Button.module.scss'

const Button = ({ href, seeMore, inline, children, className, type, hollow, plain, onClick }) => {
  const ButtonComponent = href ? TransitionLink : 'button'
  const dark = useContext(SectionDarkContext)
  const buttonType = href ? undefined : type || 'button'

  return (
    <VisibilitySensorOnce partialVisibility minTopValue={60}>
      {({ isVisible }) => (
        <Spring to={{ opacity: isVisible ? 1 : 0, translate: isVisible ? 0 : 30 }}>
          {({ opacity, translate }) => (
            <ButtonComponent
              className={cx([
                styles.button,
                inline && styles.buttonInline,
                dark && styles.buttonDark,
                hollow && styles.buttonHollow,
                plain && styles.buttonPlain,
                className,
              ])}
              exit={{
                length: 0.6,
              }}
              entry={{ delay: 0.4, length: 0.1 }}
              style={{ opacity, transform: `translateY(${translate}px)` }}
              to={href}
              type={buttonType}
              onClick={onClick}
            >
              {seeMore ? 'ZOBACZ WIĘCEJ...' : children}
            </ButtonComponent>
          )}
        </Spring>
      )}
    </VisibilitySensorOnce>
  )
}

Button.propTypes = {
  href: PropTypes.string,
  seeMore: PropTypes.bool,
  inline: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  hollow: PropTypes.bool,
  plain: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
}

Button.defaultProps = {
  href: null,
  seeMore: false,
  inline: false,
  children: null,
  className: null,
}

export { Button }
