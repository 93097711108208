import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { TransitionState } from 'gatsby-plugin-transition-link'

const VisibilitySensorOnce = ({ children, ...props }) => {
  const [active, setActive] = useState(true)
  return (
    <TransitionState>
      {({ transitionStatus }) => (
        <VisibilitySensor
          onChange={(isVisible) => transitionStatus === 'entered' && isVisible && setActive(false)}
          active={active}
          {...props}
        >
          {children}
        </VisibilitySensor>
      )}
    </TransitionState>
  )
}

export { VisibilitySensorOnce }
